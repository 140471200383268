import axios from "axios";

const basePath = `https://api.orbita.com.co/api/orbita/menu`;

const MenuService = {
  getListMenu: (token) => {
    return axios.get(basePath, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default MenuService;
