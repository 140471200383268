import { BrowserRouter } from "react-router-dom";
import MenuGeneralComponent from "./menuGeneralComponent";

export default function Root(props) {
  return (
    <BrowserRouter>
      <section>
        <MenuGeneralComponent {...props} />
      </section>
    </BrowserRouter>
  );
}
