import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { Menubar } from "primereact/menubar";
import { useLocation } from "react-router-dom";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { BreadCrumb } from "primereact/breadcrumb";
import "primeicons/primeicons.css";
import MenuService from "./MenuService";

const routerEvents = new Subject();

const MenuGeneralComponent = ({ keycloak, theme }) => {
  const [dialOpen, setDialOpen] = useState(false);
  const menu = useRef(null);
  const homeMenu = useRef(null);
  const [migas, setMigas] = useState([]);
  const location = useLocation();

  // Determine if we're in the portal view
  const isInPortal = location.pathname === "/sge/portal";

  const [menuItems, setMenuItems] = useState([]);
  const [menuModulo, setMenuModulo] = useState([]);

  useEffect(() => {
    // Fetch menu data and set menuItems and menuModulo
    MenuService.getListMenu(keycloak.token).then((response) => {
      const data = response.data;
      const currentPath = location.pathname.split("/").pop(); // Get the last segment of the path

      if (!isInPortal) {
        // Main menu items based on top-level modules, excluding current view
        const items = data
          .filter(
            (menu) => menu.modulo.toLowerCase() !== currentPath.toLowerCase()
          )
          .map((menu) => ({
            label: menu.modulo,
            command: () => {
              window.location.href = menu.ruta; // Use menu's ruta for navigation
            },
          }));

        setMenuItems(items);

        // Submenu (menuModulo) logic for the current module
        const currentModule = data.find((menu) =>
          location.pathname.includes(menu.ruta.split("/").pop())
        );
        if (currentModule && currentModule.subMenus) {
          const subItems = currentModule.subMenus.map((sub) => ({
            label: sub.titulo,
            command: () => {
              window.location.href = `${currentModule.ruta}${sub.ruta}`;
            },
          }));
          setMenuModulo([
            {
              label: "Inicio",
              command: () => {
                window.location.href = "/";
              },
            },
            { label: "Wiki" },
            { label: "Soporte" },
            ...subItems,
          ]);
        } else {
          setMenuModulo([
            {
              label: "Inicio",
              command: () => {
                window.location.href = "/";
              },
            },
            { label: "Wiki" },
            { label: "Soporte" },
          ]);
        }
      }
    });

    // Breadcrumb logic
    const obtenerMigas = () => {
      const segments = location.pathname
        .split("/")
        .filter((segment) => segment);
      const newMigas = segments.map((segment, index) => {
        const isFirst = index === 0;
        const path = `/${segments.slice(0, index + 1).join("/")}`;
        return {
          label: (
            <span className="mx-1">
              {isFirst && segment === "sge" ? (
                <i className="pi pi-home" />
              ) : (
                segment
              )}
            </span>
          ),
          url: path,
        };
      });
      setMigas(newMigas);
    };

    const subscription = routerEvents
      .pipe(filter((event: any) => event.event === "NavigationEnd"))
      .subscribe(obtenerMigas);

    obtenerMigas();

    return () => {
      subscription.unsubscribe();
    };
  }, [location, keycloak.token, isInPortal]);

  const handleChangeTheme = () => {
    theme();
  };

  const menuConfiguracion = [
    {
      label: "Inicio",
      command: () => {
        window.location.href = "/sge/portal/";
      },
    },
    {
      label: "Soporte",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Wiki",
      command: () => {
        window.location.hash = "/portal/wiki";
      },
    },
    {
      label: "Perfil",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Theme",
      icon: "pi pi-palette",
      command: handleChangeTheme,
    },
    {
      label: "Salir",
      icon: "pi pi-power-off",
      command: () => {
        keycloak.logout();
      },
    },
  ];

  return (
    <>
      <header
        className="flex justify-content-between"
        style={{ height: "45px" }}
      >
        <div className="flex align-items-center">
          {!isInPortal && (
            <>
              <Button
                className="mr-1"
                icon="pi pi-bars"
                onClick={(e) => menu.current.toggle(e)}
              />
              <TieredMenu
                model={menuModulo}
                popup
                ref={menu}
                breakpoint="767px"
              />
            </>
          )}
          <BreadCrumb model={migas} className="max-w-full p-2" />
        </div>
        <div className="flex align-items-center">
          {dialOpen && <Menubar model={menuConfiguracion} menuIcon={false} />}
          <Button
            className="ml-1"
            icon="pi pi-cog"
            onClick={() => setDialOpen(!dialOpen)}
          />
        </div>
      </header>

      {menuItems.length > 0 && !isInPortal && (
        <div>
          <Button
            icon="pi pi-home"
            style={{
              position: "fixed",
              bottom: "1%",
              left: "1%",
              zIndex: 10000,
            }}
            onClick={(e) => homeMenu.current.toggle(e)}
          />
          <TieredMenu model={menuItems} popup ref={homeMenu} />
        </div>
      )}
    </>
  );
};

export default MenuGeneralComponent;
